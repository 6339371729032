import React, { useState, useEffect } from "react";
import { Row, Col, Select } from "antd";
import { getMessagePreviewData } from "../../../Adapters/BroadcastmessageCalls";
import DateTimeFormatter from "../../../components/Utils/DateTimeFormatter";
const DraftModal = ({
    draftModalData,
    editDraftHandler,
    mainTabKey,
    messageID,
}) => {
    const { Option, OptGroup } = Select;
    const [value, setValue] = useState("English");
    const [selectedSubDeptId, setSelectedSubDeptId] = useState("");
    const [previewData, setPreviewData] = useState("");
    const [options, setOption] = useState("");
    const [selectedFactorValues, setSelectedFactoryValues] = useState();

    const languageValueHandler = (val) => {
        setValue(val);
    };
    const handleSubDeptSelection = (department, subDepartment) => {
        // Update the selected values state
        setSelectedSubDeptId(() => ({
            [department]: subDepartment,
        }));
    };
    const factoryValueHandler = (val) => {
        setSelectedFactoryValues(val);
    };

    function toPascalCase(str) {
        return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
            return match.toUpperCase();
        });
    }
    const previewDraftData = async () => {
        if (value && selectedSubDeptId && selectedFactorValues) {
            let queryUrl = new URL(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/savedraft`
            );
            const departmentParam = JSON.stringify(selectedSubDeptId);
            queryUrl.searchParams.append("draftID", messageID);
            queryUrl.searchParams.append("department", departmentParam);
            queryUrl.searchParams.append("language", value);
            queryUrl.searchParams.append("factory", selectedFactorValues);
            const res = await getMessagePreviewData(queryUrl);

            setPreviewData(res?.data.message_body);
        }
    };

    const previewMessageData = async () => {
        let category;
        if (mainTabKey === "2") {
            category = "Scheduled";
        } else {
            category = "Sent";
        }
        if (value && selectedSubDeptId && selectedFactorValues) {
            let queryUrl = new URL(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/ScheduleSentDashboards/?category=${category}`
            );
            const departmentParam = JSON.stringify(selectedSubDeptId);
            queryUrl.searchParams.append("messageID", messageID);
            queryUrl.searchParams.append("department", departmentParam);
            queryUrl.searchParams.append("language", value);
            queryUrl.searchParams.append("factory", selectedFactorValues);
            const res = await getMessagePreviewData(queryUrl);

            setPreviewData(res?.data.message_body);
        }
    };

    if (draftModalData) {
    }
    const result = [];
    const d = draftModalData?.subDepartments.forEach((item) => {
        if (!result.includes(item.Department)) {
            result.push(item.Department);
        }
        if (!result.includes(item.SubDepartment)) {
            result.push(item.SubDepartment);
        }
    });

    useEffect(() => {
        if (mainTabKey === "3") {
            previewDraftData();
        } else {
            previewMessageData();
        }
        if (draftModalData) {
            const options = draftModalData?.subDepartments.reduce(
                (acc, curr) => {
                    if (!acc[curr.Department]) {
                        acc[curr.Department] = [];
                    }
                    acc[curr.Department].push(curr);
                    return acc;
                },
                {}
            );
            setOption(options);
        }
    }, [value, selectedSubDeptId, selectedFactorValues, draftModalData]);

    return (
        <div>
            <Row gutter={(12, 24)}>
                <Col span={12}>
                    <Row>
                        <Col flex="2" style={{ fontWeight: "bold" }}>
                            {draftModalData?.Status === "Sent"
                                ? "Date & Time"
                                : "Last Modified"}
                        </Col>
                        <Col
                            flex="3"
                            style={{
                                marginBottom: " 2rem",
                                flexFlow: "unset",
                                borderBottom: "1px solid #BEBEBE",
                            }}>
                            {mainTabKey === "2" || mainTabKey === "4" ? (
                                <DateTimeFormatter
                                    dateTimeString={`${draftModalData?.Date} ${draftModalData?.Time}`}
                                />
                            ) : (
                                <DateTimeFormatter
                                    dateTimeString={
                                        draftModalData?.lastModified
                                    }
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col flex="2" style={{ fontWeight: "bold" }}>
                            Total SMS count
                        </Col>
                        <Col
                            flex="3"
                            style={{
                                marginBottom: " 2rem",
                                flexFlow: "unset",
                                borderBottom: "1px solid #BEBEBE",
                            }}>
                            {draftModalData?.TotalSMSCount}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={(12, 24)}>
                <Col span={12}>
                    <Row>
                        <Col flex="2" style={{ fontWeight: "bold" }}>
                            {draftModalData?.Status === "Sent"
                                ? "Sent By"
                                : "Created By"}
                        </Col>
                        <Col
                            flex="3"
                            style={{
                                marginBottom: " 2rem",
                                flexFlow: "unset",
                                borderBottom: "1px solid #BEBEBE",
                            }}>
                            {draftModalData?.createdBy}
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col flex="2" style={{ fontWeight: "bold" }}>
                            Template
                        </Col>
                        <Col
                            flex="3"
                            style={{
                                marginBottom: " 2rem",
                                flexFlow: "unset",
                                borderBottom: "1px solid #BEBEBE",
                            }}>
                            {draftModalData?.Template}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="start" style={{ marginBottom: "2rem" }}>
                <Col>
                    <Select
                        value={value}
                        onChange={languageValueHandler}
                        style={{ width: "90%", marginRight: "1rem" }}>
                        {draftModalData?.language.map((val) => (
                            <Option key={val} value={val}>
                                {val}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col>
                    <Select
                        placeholder="Factory"
                        onChange={factoryValueHandler}
                        style={{ width: "90%", marginRight: "1rem" }}>
                        {draftModalData?.Factories.map((val) => (
                            <Option key={val.id} value={val.id}>
                                {val.Code}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col style={{ width: "20%" }}>
                    <Select
                        placeholder="Department"
                        onChange={(value, option) => {
                            const [department, subDepartment] =
                                value.split("-");

                            handleSubDeptSelection(department, subDepartment);
                        }}
                        style={{ width: "90%", marginRight: "1rem" }}>
                        {Object.keys(options)?.map((department) => (
                            <OptGroup label={department} key={department}>
                                {options[department].map((subDept) => (
                                    <Option
                                        key={`${department}-${subDept.SubDepartment}`}
                                        value={`${department}-${subDept.SubDepartment}`}>
                                        {toPascalCase(subDept.SubDepartment)}
                                    </Option>
                                ))}
                            </OptGroup>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Row gutter={(12, 24)}>
                <Col span={12}>
                    <Row>
                        <Col flex="2" style={{ fontWeight: "bold" }}>
                            Message:
                        </Col>
                        <Col
                            flex="3"
                            style={{
                                marginBottom: " 2rem",
                                flexFlow: "unset",
                                borderBottom: "1px solid #BEBEBE",
                            }}>
                            {previewData?.Message ? previewData?.Message : "-"}
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col flex="2" style={{ fontWeight: "bold" }}>
                            SMS Count
                        </Col>
                        <Col
                            flex="3"
                            style={{
                                marginBottom: " 2rem",
                                flexFlow: "unset",
                                borderBottom: "1px solid #BEBEBE",
                            }}>
                            {previewData?.SMSCount >= 0
                                ? previewData?.SMSCount
                                : "-"}
                        </Col>
                    </Row>
                    {(mainTabKey === "2" || mainTabKey === "4") && (
                        <Row>
                            <Col flex="2" style={{ fontWeight: "bold" }}>
                                Time Zone
                            </Col>
                            <Col
                                flex="3"
                                style={{
                                    marginBottom: " 2rem",
                                    flexFlow: "unset",
                                    borderBottom: "1px solid #BEBEBE",
                                }}>
                                {previewData?.Timezone}
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            {draftModalData?.Status === "Draft" &&
            draftModalData?.editAccess === "Yes" ? (
                <>
                    <hr></hr>
                    <Row justify="end">
                        <Col>
                            <button
                                className="secondaryButton"
                                onClick={editDraftHandler}>
                                Edit
                            </button>
                        </Col>
                    </Row>
                </>
            ) : null}
        </div>
    );
};

export default DraftModal;
