import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { TranslateLanguage } from "../../Adapters/translationAPICalls";
import _ from "lodash";

const MultiOutputTranslation = ({
    language,
    varInput,
    placeHolder,
    variable,
    changeFlag,
    setTranslatedDataObj,
    translatedDataObj,
    isTemplateValue,
}) => {
    const [translatedText, setTranslatedText] = useState("");

    useEffect(() => {
        if (translatedText?.length > 0) {
            const newObj = { ...translatedDataObj };
            newObj[variable] = varInput;
            setTranslatedDataObj({ ...translatedDataObj, ...newObj });
        }
    }, [translatedText]);

    useEffect(() => {
        setTranslatedText("");
        setTranslatedDataObj({});
    }, [isTemplateValue]);

    useEffect(() => {
        translateVariableData();
    }, [changeFlag, language]);
    const languageMappings = {
        english: "en",
        hindi: "hi",
        kannada: "kn",
        punjabi: "pa",
        telugu: "te",
        tamil: "ta",
        oriya: "or",
        odia: "or",
        bangla: "bn",
        // Add more languages as needed
    };
    const translateVariableData = async () => {
        // checking the language and then running the api calll
        let ln;
        const lowercaseLanguage = String(language).toLowerCase();

        if (lowercaseLanguage === "english") {
            // no need of API call if the language is English
            setTranslatedText(varInput);
            ln = "en";
            return;
        } else if (languageMappings.hasOwnProperty(lowercaseLanguage)) {
            ln = languageMappings[lowercaseLanguage];
        } else {
            // Handle unsupported languages or set a default language code
            ln = "default"; // Replace with your default language code or handle accordingly
        }

        try {
            if (varInput?.trim()) {
                var res = await TranslateLanguage(varInput, "en", ln);
                setTranslatedText(
                    res?.data?.data?.translations[0].translatedText
                );
            }
        } catch (error) {
            console.warn(error);
        }
    };

    return (
        <div>
            <Input.TextArea
                placeholder={`Translation in ${placeHolder}`}
                value={translatedText}
                showCount
                readOnly={true}
                rows={4}
                cols={50}
                maxLength={30}
            />
        </div>
    );
};

export default MultiOutputTranslation;
