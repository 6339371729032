import axios from "axios";
import { useRecoilState } from "recoil";
import { userState } from "../RecoilState/userState";
import { common_axios } from "../utils/AxiosSettings";
export async function fetchManagers(company_fk, factory_fk) {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/users/?role=CM&operation=filter&company=${company_fk}&factory=${factory_fk}`
    );
    return res;
}
export async function fetchTRB(company_fk, factory_fk) {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/users/?role=CT&operation=filter&company=${company_fk}&factory=${factory_fk}`
    );
    return res;
}
export async function fetchRA(company_fk, factory_fk) {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/users/?role=REGIONAL_ADMIN&operation=filter&company=${company_fk}&factory=${factory_fk}`
    );
    return res;
}
export async function PatchCaseData(id, CaseDataObject) {
    const res = await common_axios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/Cases/${id}`,
        CaseDataObject
    );
    return res;
}

export async function putCaseData(id, CaseDataObject) {
    const res = await common_axios.put(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/Cases/${id}`,
        CaseDataObject
    );
    return res;
}

export async function updateCaseManagerOfCase(id, manager, company, factory) {
    const res = await common_axios.put(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/Cases/${id}/manager/`,
        {
            CaseManager: manager,
            Company: company,
            Factory: factory,
        }
    );
    return res;
}

export async function updateCaseTRBOfCase(
    id,
    TroubleShooter,
    company,
    factory
) {
    const res = await common_axios.put(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/Cases/${id}/trbshooter/`,
        {
            CaseTroubleShooter: TroubleShooter,
            Company: company,
            Factory: factory,
        }
    );
    return res;
}

export async function saveClosingReport(CaseDataClosingObject) {
    const res = await common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/Cases/report/upload/`,
        CaseDataClosingObject
    );
    return res;
}

export async function saveClosingCaseReportCall(
    ClosingReport,
    caseID,
    reopen,
    companyID,
    translatedDataObj
) {
    let objectString = JSON.stringify(translatedDataObj);
    const res = await common_axios.put(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/case/resolve/?Method=Sent`,
        {
            Case: caseID,
            CCRWhen: ClosingReport.when,
            CCRWhathappened: ClosingReport.what,
            CCRWho: ClosingReport.who,
            CCRremarks: ClosingReport.remarks,
            CCTemplate: ClosingReport.template,
            CCLanguage: ClosingReport.language,
            CCMessage: ClosingReport.message ? ClosingReport.message : "",
            CCTemplateID: ClosingReport.templateID,
            Company: companyID,
            CCRvariables: objectString,

            CCRComments_RA: ClosingReport.CCRComments_RA
                ? ClosingReport.CCRComments_RA
                : "",
            CCRAction: ClosingReport.action,
            CCRisResolved: ClosingReport.isResolved,
        }
    );

    return res.data;
}

export async function draftClosingCaseReportCall(
    ClosingReport,
    caseID,
    companyID
) {
    const res = await common_axios.put(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/case/resolve/?Method=Draft`,
        {
            Case: caseID,
            CCRWhen: ClosingReport.when,
            CCRWhere: ClosingReport.where,
            CCRWhathappened: ClosingReport.what,
            CCRWho: ClosingReport.who,
            CCRremarks: ClosingReport.remarks,
            CCTemplate: ClosingReport.template,
            CCLanguage: ClosingReport.language,
            CCMessage: ClosingReport.message ? ClosingReport.message : "",
            CCTemplateID: ClosingReport.templateID,
            Company: companyID,
            CCRComments_RA: ClosingReport.CCRComments_RA,
            CCRAction: ClosingReport.action,
            CCRisResolved: ClosingReport.isResolved,
        }
    );

    return res;
}

export async function caseUploadCaller(formData) {
    const res = await common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/CaseswithFileUpload/`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
    return res.data;
}

export async function getCasereportData(caseID, version) {
    let url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/case/resolve/?caseID=${caseID}`;

    if (version) {
        url += `&version=${version}`;
    }

    const res = await common_axios.get(url);

    return res.data;
}
export async function ReopenCaseCTBySLA(caseID) {
    const res = common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/Case/ct/reopen/sla/`,
        {
            Case: caseID,
        }
    );
    return res;
}
export async function ResolveCaseByCT(caseID) {
    const res = common_axios.put(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/case/${caseID}/close/
`
    );
    return res;
}

export async function getLanguageList() {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/getLanguages/`
    );

    return res;
}

export async function getCategories(company_fk) {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/category/?Company=${company_fk}`
    );
    return res;
}
