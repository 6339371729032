import { Modal } from "antd";
import React from "react";
import "../../../common.css";
import { GLOBAL_LINKS } from "../../../constants";

const GeneralInstructionModal = ({ open, setOpen }) => {
    return (
        <Modal
            style={{ textAlign: "left" }}
            title={"General Instructions"}
            open={open}
            footer={[
                <button
                    key="ok"
                    className="primaryButton"
                    onClick={() => setOpen(false)}>
                    Understood
                </button>,
            ]}
            width={350}
            closeIcon>
            <div>
                <p>
                    To complete your quality check of this case file, please
                    review each section individually before approving or
                    reopening.
                    <br />
                    <br />
                    Please refer to your{" "}
                    <a href={GLOBAL_LINKS.QC_SOP_LINK} target="_blank">
                        Standard Operating Procedure
                    </a>{" "}
                    to guide your review.
                </p>
            </div>
        </Modal>
    );
};

export default GeneralInstructionModal;
